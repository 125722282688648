import React, { useState } from 'react';
import { useQuery, useMutation, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import { Dialog, Switch } from '@headlessui/react';
import Navbar from "../Navbar.jsx";

const baseUrl = 'https://du.musama.net/api'; // Replace with your actual base URL

const fetchPeserta = async () => {
    const { data } = await axios.get(`${baseUrl}/peserta/madinku/`);
    return data;
};

const updatePeserta = async ({ id, ...payload }) => {
    const { data } = await axios.patch(`${baseUrl}/peserta/madinku/${id}`, payload);
    return data;
};
const addPeserta = async (payload) => {
    const { data } = await axios.post(`${baseUrl}/peserta/madinku/single/`, payload);
    return data;
};

const DataSiswa = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedPeserta, setSelectedPeserta] = useState(null);
    const [statusTaftisan, setStatusTaftisan] = useState(false);
    const [statusPembayaran, setStatusPembayaran] = useState('');
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [newPeserta, setNewPeserta] = useState({
        name: '',
        nis: '',
        rfid: '',
        status_taftisan: false,
        status_pembayaran: 'belum',
    });

    const addMutation = useMutation({
        mutationFn: addPeserta,
        onSuccess: () => {
            refetch();
            setIsAddModalOpen(false);
            setNewPeserta({
                name: '',
                nis: '',
                rfid: '',
                status_taftisan: false,
                status_pembayaran: 'belum',
            });
        },
    });

    const handleAdd = () => {
        setIsAddModalOpen(true);
    };

    const handleAddSave = () => {
        addMutation.mutate(newPeserta);
    };

    const { data: listPeserta, isLoading, isError, refetch } = useQuery({
        queryKey: ['peserta'],
        queryFn: fetchPeserta,
    });

    const mutation = useMutation({
        mutationFn: updatePeserta,
        onSuccess: () => {
            // Refetch data after successful mutation
            refetch();
            setIsEditModalOpen(false);
        },
    });

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredPeserta = listPeserta?.filter(
        (peserta) =>
            (peserta.name?.toLowerCase().includes(searchTerm.toLowerCase()) || // Handle null name
                peserta.rfid?.includes(searchTerm)) // Handle null rfid
    );

    const handleEdit = (peserta) => {
        setSelectedPeserta(peserta);
        setStatusTaftisan(peserta.status_taftisan);
        setStatusPembayaran(peserta.status_pembayaran);
        setIsEditModalOpen(true);
    };

    const handleSave = () => {
        mutation.mutate({
            id: selectedPeserta._id,
            status_taftisan: statusTaftisan,
            status_pembayaran: statusPembayaran,
        });
    };

    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error fetching data</div>;

    return (
        <div className="pb-10 px-2">
            <div className="mb-4 flex gap-2">
                <input
                    type="text"
                    placeholder="Cari nama atau RFID"
                    value={searchTerm}
                    onChange={handleSearch}
                    className="w-full p-2 border border-gray-300 rounded"
                />
                <button
                    onClick={refetch} // Refresh data when clicked
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                    Refresh
                </button>
                <button
                    onClick={handleAdd}
                    className="bg-green-500 text-white px-4 py-2 rounded"
                >
                    Tambah
                </button>
            </div>

            <table className="min-w-full bg-white border border-gray-300">
                <thead>
                <tr>
                    <th className="py-2 px-1 border-b">Nama</th>
                    <th className="py-2 px-1 border-b">Cek Kitab?</th>
                    <th className="py-2 px-1 border-b">Pembayaran</th>
                    <th className="py-2 px-1 border-b">Aksi</th>
                    <th className="py-2 px-1 border-b">No</th>
                    <th className="py-2 px-1 border-b">Rfid</th>
                </tr>
                </thead>
                <tbody>
                {filteredPeserta?.map((peserta, index) => (
                    <tr key={peserta._id} className={`${index % 2 === 0 ? 'bg-gray-100' : ''}`}>
                        <td className="py-2 px-1 border-b">{peserta.name}</td>


                        <td className="py-2 px-4 border-b">                <span
                            className={`inline-block px-2 py-1 rounded ${
                                peserta.status_taftisan ? 'bg-green-600 text-white' : 'bg-red-600 text-white italic'
                            }`}
                        >
                  {peserta.status_taftisan ? 'Penuh' : 'Gotang'}

                </span>
                        </td>
                        <td className="py-2 px-4 border-b">


                            <span
                                className={`inline-block px-2 py-1 rounded ${
                                    peserta.status_pembayaran === 'sudah' ? 'bg-green-200' : 'bg-red-200'
                                }`}
                            >
                  {peserta.status_pembayaran}
                </span>
                        </td>
                        <td className="py-2 px-4 border-b">
                            <button
                                onClick={() => handleEdit(peserta)}
                                className="bg-blue-500 text-white px-2 py-1 rounded"
                            >
                                Edit
                            </button>
                        </td>
                        <td className="py-2 2 text-center border-b text-xs">{index + 1}</td>

                        <td className="py-2 px-1 border-b">{peserta.rfid}</td>
                    </tr>
                ))}
                </tbody>
            </table>

            <Dialog open={isAddModalOpen} onClose={() => setIsAddModalOpen(false)}
                    className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex items-center justify-center min-h-screen">
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                    <div className="bg-white rounded-lg p-6 z-20">
                        <Dialog.Title className="text-lg font-bold">Add Peserta</Dialog.Title>
                        <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700">Nama</label>
                            <input
                                type="text"
                                value={newPeserta.name}
                                onChange={(e) => setNewPeserta({ ...newPeserta, name: e.target.value })}
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            />
                        </div>
                        <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700">NIS</label>
                            <input
                                type="text"
                                value={newPeserta.nis}
                                onChange={(e) => setNewPeserta({ ...newPeserta, nis: e.target.value })}
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            />
                        </div>
                        <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700">RFID</label>
                            <input
                                type="text"
                                value={newPeserta.rfid}
                                onChange={(e) => setNewPeserta({ ...newPeserta, rfid: e.target.value })}
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            />
                        </div>
                        <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700">Checkingan</label>
                            <Switch
                                checked={newPeserta.status_taftisan}
                                onChange={(checked) => setNewPeserta({ ...newPeserta, status_taftisan: checked })}
                                className={`${
                                    newPeserta.status_taftisan ? 'bg-blue-600' : 'bg-gray-200'
                                } relative inline-flex h-6 w-11 items-center rounded-full`}
                            >
                                <span className="sr-only">Enable notifications</span>
                                <span
                                    className={`${
                                        newPeserta.status_taftisan ? 'translate-x-6' : 'translate-x-1'
                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                />
                            </Switch>
                        </div>
                        <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700">Status Pembayaran</label>
                            <select
                                value={newPeserta.status_pembayaran}
                                onChange={(e) => setNewPeserta({ ...newPeserta, status_pembayaran: e.target.value })}
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            >
                                <option value="belum">Belum</option>
                                <option value="sudah">Sudah</option>
                                <option value="cicil">Cicil</option>
                            </select>
                        </div>
                        <div className="mt-6 flex justify-end">
                            <button
                                onClick={() => setIsAddModalOpen(false)}
                                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleAddSave}
                                className="bg-blue-500 text-white px-4 py-2 rounded"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog open={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}
                    className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex items-center justify-center min-h-screen">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                    <div className="bg-white rounded-lg p-6 z-20">
                        <Dialog.Title className="text-lg font-bold">Edit Peserta</Dialog.Title>
                        <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700">Nama</label>
                            <input
                                type="text"
                                value={selectedPeserta?.name || ''}
                                disabled
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            />
                        </div>
                        <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700">Checkingan</label>
                            <Switch
                                checked={statusTaftisan}
                                onChange={setStatusTaftisan}
                                className={`${
                                    statusTaftisan ? 'bg-blue-600' : 'bg-gray-200'
                                } relative inline-flex h-6 w-11 items-center rounded-full`}
                            >
                                <span className="sr-only">Enable notifications</span>
                                <span
                                    className={`${
                                        statusTaftisan ? 'translate-x-6' : 'translate-x-1'
                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                />
                            </Switch>
                        </div>
                        <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700">Status Pembayaran</label>
                            <select
                                value={statusPembayaran}
                                onChange={(e) => setStatusPembayaran(e.target.value)}
                                className="mt-1 p-2 border border-gray-300 rounded w-full"
                            >
                                <option value="sudah">Sudah</option>
                                <option value="belum">Belum</option>
                                <option value="perjanjian">Perjanjian</option>
                            </select>
                        </div>
                        <div className="mt-6 flex justify-end">
                            <button
                                onClick={() => setIsEditModalOpen(false)}
                                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleSave}
                                className="bg-blue-500 text-white px-4 py-2 rounded"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

const queryClient = new QueryClient();

const DataSiswaWrapper = () => (
    <QueryClientProvider client={queryClient}>
        <Navbar></Navbar>
        <DataSiswa />
    </QueryClientProvider>
);

export default DataSiswaWrapper;