
// const baseURL = 'https://103.127.98.123';
// const baseURL = 'https://192.168.0.3:5001';
// const baseURL = 'https://103.59.160.92:5001';
const baseURL = 'https://madinku.my.id/api';
// const baseURL = 'https://arsip.madinku.my.id/api';
// const baseURL = 'https://mdn.darussaadah.net';
// const baseURL = 'https://madinku.localto.net';

export default baseURL;
