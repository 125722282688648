import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Dialog } from "@headlessui/react";
// Import audio files
import notFoundAudio from "./not_found.mp3";
import notQuaAudio from "./not_qua.mp3";
import lateAudio from "./late.mp3";
import passAudio from "./pass.mp3";
import Navbar from "../../Navbar.jsx";
import '../gaya.css'
const ScannerUjian = () => {
    const [listPeserta, setListPeserta] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [tvText, setTvText] = useState("Scanner Ujian");
    const [tvText2, setTvText2] = useState("Scanner Ujian");
    const [tvText3, setTvText3] = useState("");
    const [bgColor, setBgColor] = useState("bg-gray-200");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [lateTime, setLateTime] = useState("21:20");
    const searchBoxRef = useRef(null);
    const [isFlashing, setIsFlashing] = useState(false);


    const audioRefs = {
        not_found: useRef(null),
        not_qua: useRef(null),
        late: useRef(null),
        pass: useRef(null),
    };

    useEffect(() => {
        audioRefs.not_found.current = new Audio(notFoundAudio);
        audioRefs.not_qua.current = new Audio(notQuaAudio);
        audioRefs.late.current = new Audio(lateAudio);
        audioRefs.pass.current = new Audio(passAudio);
    }, []);

    const baseUrl = 'https://du.musama.net/api'; // Replace with your actual base URL

    const handleGetData = async () => {
        try {
            const response = await axios.get(`${baseUrl}/peserta/madinku/`);
            setListPeserta(response.data);
            localStorage.setItem("listPeserta", JSON.stringify(response.data));
            const now = new Date();
            // Format the date and time for UTC+7 timezone in Indonesian format
            const dateFormatter = new Intl.DateTimeFormat('id-ID', {
                timeZone: 'Etc/GMT-7',
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false,
                hourCycle: 'h23'
            });


            const formattedDate = dateFormatter.format(now);

            // console.log(`Waktu dan Tanggal Saat Ini (UTC+7): ${formattedDate}`);
            setTvText3(`Terakhir Ambil data dari Server: ${formattedDate}`);

            toast.success("Data peserta berhasil diambil dan disimpan!");
        } catch (error) {
            toast.error("Gagal mengambil data peserta.");
        }
    };

    const changeBgColor = (color) => {
        setIsFlashing(true); // Trigger flash effect
        setTvText(``);
        setTvText2(``);
        setTimeout(() => {
            setIsFlashing(false); // Remove flash effect
            setBgColor(color); // Apply the new color
        }, 100); // Flash duration (200ms)
    };


    const handleSearch = () => {
        const peserta = listPeserta.find(
            (p) => p.nis === searchValue || p.rfid === searchValue
        );
        console.log(peserta);

        if (!peserta) {
            changeBgColor("bg-orange-600");
            setTvText("DATA TIDAK DITEMUKAN");
            setTvText2(`  `);
            playAudio("not_found");
            clearAndFocusSearchBox();
            return;
        }
        let statusbyr = peserta.status_pembayaran ==="sudah" || peserta.status_pembayaran ==="perjanjian";
        console.log(statusbyr);


        if (!peserta.status_taftisan && !statusbyr) {
            changeBgColor("bg-red-500");
            setTvText(` ----- DITOLAK! -----`);
            setTvText2(`${peserta.name} Kitab Belum Penuh & pembayaran Belum Lunas`);
            playAudio("not_qua");
            clearAndFocusSearchBox();
            return;
        }
        if (peserta.status_taftisan && !statusbyr) {
            changeBgColor("bg-red-500");
            setTvText(` ----- DITOLAK! -----`);
            setTvText2(`${peserta.name}  Pembayaran Belum Lunas`);
            playAudio("not_qua");
            clearAndFocusSearchBox();
            return;
        }
        if (!peserta.status_taftisan && statusbyr) {
            setBgColor("bg-red-500");
            setTvText(` ----- DITOLAK! -----`);
            setTvText2(`${peserta.name}  Kitab belum penuh`);
            playAudio("not_qua");
            clearAndFocusSearchBox();
            return;
        }


        const now = new Date();
        const [lateHour, lateMinute] = lateTime.split(":").map(Number);
        const lateTimeDate = new Date();
        lateTimeDate.setHours(lateHour, lateMinute, 0);

        if (now > lateTimeDate) {
            changeBgColor("bg-yellow-500");
            setTvText(`Terlambat!: ${peserta.name}`);
            setTvText2(`Terlambat`);
            playAudio("late");
        } else {
            changeBgColor("bg-green-600");
            setTvText(`DITERIMA!, ${peserta.name}`);
            setTvText2(`  Kitab Sudah Penuh dan Pembayaran Sudah Lunas/Perjanjian`);
            playAudio("pass");
        }
        clearAndFocusSearchBox();
    };

    const clearAndFocusSearchBox = () => {
        setSearchValue("");
        searchBoxRef.current.focus();
    };

    const handleFocus = () => {
        searchBoxRef.current.focus();
    };

    const playAudio = (type) => {
        // Stop all currently playing audio
        Object.values(audioRefs).forEach((audioRef) => {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0; // Reset audio to the beginning
            }
        });

        // Play the selected audio
        const audio = audioRefs[type]?.current;
        if (audio) {
            audio.play().catch((error) => {
                console.error("Audio playback failed:", error);
            });
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };

    return (
        <div className={`w-full h-screen ${bgColor} flex flex-col ${isFlashing ? "flash" : ""}`}>

            <Toaster/>
            <Navbar></Navbar>
            <div className="flex items-center space-x-4 mb-4 pt-5 pl-4">
                <input
                    ref={searchBoxRef}
                    type="text"
                    placeholder="Cari RFID, Nis"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="p-2 border border-gray-400 rounded-md focus:outline-none"
                />
                <button
                    onClick={handleSearch}
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700"
                >
                    Cari
                </button>
            </div>
            <div className="flex space-x-4 mb-6 pl-4">
                <button
                    onClick={handleGetData}
                    className="px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-900"
                >
                    Ambil Data
                </button>
                <button
                    onClick={handleFocus}
                    className="px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-900"
                >
                    Fokus
                </button>
                <button
                    onClick={() => setIsDialogOpen(true)}
                    className="px-4 py-2 bg-gray-800 text-white rounded-md hover:bg-gray-900"
                >
                    Waktu
                </button>
            </div>
            <span className="flex items-center space-x-4">{tvText3}</span>
            <div className="text-5xl font-bold text-white text-center p-4">
                {tvText}
                <br/>
                <br/>
                {tvText2}
            </div>
            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg">
                        <h2 className="text-lg font-bold mb-4">Set Late Time</h2>
                        <input
                            type="time"
                            value={lateTime}
                            onChange={(e) => setLateTime(e.target.value)}
                            className="p-2 border border-gray-400 rounded-md focus:outline-none"
                        />
                        <div className="mt-4 flex justify-end">
                            <button
                                onClick={() => setIsDialogOpen(false)}
                                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700"
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default ScannerUjian;
